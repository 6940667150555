import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import '../css/Associados.css';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import { LinkContainer } from 'react-router-bootstrap';
import 'react-table/react-table.css';
import { Icon } from 'react-icons-kit';
import {download} from 'react-icons-kit/icomoon/download'
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import $ from 'jquery';
import axios from 'axios';
import {iosSearchStrong} from 'react-icons-kit/ionicons/iosSearchStrong'
import { edit } from 'react-icons-kit/ionicons/edit';
import {ic_more} from 'react-icons-kit/md/ic_more'
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName, populateForm, dateSql, removeAcento, garanteDate, onBlurCurrency } from '../Utils';
import swal from '@sweetalert/with-react';
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroAssociados extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			novaImagem: false,
			modalArquivo: { show: false },
			modalAnuidade: { show: false },
			selected: {
				cidadeNaturalidade: [],
				cidadeCom: [],
				cidadeResid: [],
				uniFormacao: [],
				instDoutorado: [],
				instMestrado: [],
				categoria: [],
				regiao: [],
				cet: []
			},
			temImagem: 'N',
			dataAnuidades: [],
			image: {},
			arquivo: {},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.selectCidadeNaturalidade = this.selectCidadeNaturalidade.bind(this);
		this.selectCidadeCom = this.selectCidadeCom.bind(this);
		this.selectCidadeResid = this.selectCidadeResid.bind(this);
		this.selectCategoria = this.selectCategoria.bind(this);
		this.selectInstDoutorado = this.selectInstDoutorado.bind(this);
		this.selectInstMestrado = this.selectInstMestrado.bind(this);
		this.selectCategoriaArquivo = this.selectCategoriaArquivo.bind(this);
		this.selectRegiao = this.selectRegiao.bind(this);
		this.selectUniversidade = this.selectUniversidade.bind(this);
		this.handleCep = this.handleCep.bind(this);
		this.selectCet = this.selectCet.bind(this);
		this.submitData = this.submitData.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.submitArquivo = this.submitArquivo.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSaveArquivo = this.handleSaveArquivo.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.handleAddArquivo = this.handleAddArquivo.bind(this);
		this.showModalArquivo = this.showModalArquivo.bind(this);
		this.showModalAnuidade = this.showModalAnuidade.bind(this);
		this.handleSelectedFile = this.handleSelectedFile.bind(this);
		this.handleSelectedImage = this.handleSelectedImage.bind(this);
		this.handleDownloadBoleto = this.handleDownloadBoleto.bind(this)
		this.handleAddAnuidade = this.handleAddAnuidade.bind(this)
		this.handleSaveAnuidade = this.handleSaveAnuidade.bind(this)
		this.submitAnuidade = this.submitAnuidade.bind(this)
		this.handleEditAnuidade = this.handleEditAnuidade.bind(this)
		this.handleCpf = this.handleCpf.bind(this)
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleCep(e, name){
		e.preventDefault()
		let value = document.getElementsByName(name)[0].value;
        if (value.length >= 8){
            let cep = value
            fetch('https://viacep.com.br/ws/'+cep.replace(/[^\d]/, '')+'/json/').then(r => r.json()).then(r => {
                if (r.erro !== true){
                    console.log(r)
                    let bairro = removeAcento(r.bairro).toUpperCase()
                    let logradouro = removeAcento(r.logradouro).toUpperCase()
                    let localidade = removeAcento(r.localidade).toUpperCase()
					let uf = removeAcento(r.uf).toUpperCase()
					let selected = this.state.selected
					let cidades = this.state.combos.filter((item) => {
						return item.type === 'cidade' && removeAcento(item.display).toUpperCase() === localidade+' - '+uf;
					});
					const form = document.getElementById('registroAssociados');
					const data = new FormData(form);
					let object = {};
					data.forEach((value, key) => {object[key] = value});
					// var json = JSON.stringify(object);
					if (name === 'cep_resid') {
						if (cidades.length>0) {
							selected.cidadeResid = cidades[0]
						} else selected.cidadeResid = []
						object.endereco_resid = logradouro+', N° '
						object.bairro_resid = bairro
						populateForm(form, object)
						this.setState({ selected: selected })
					} else {
						if (cidades.length>0) {
							selected.cidadeCom = cidades[0]
						} else selected.cidadeCom = []
						object.endereco_com = logradouro+', N° '
						object.bairro_com = bairro
						populateForm(form, object)
						this.setState({ selected: selected })
					}
                } else {
					alert('CEP inválido!')
					const form = document.getElementById('registroAssociados');
					const data = new FormData(form);
					let object = {};
					data.forEach((value, key) => {object[key] = value});
					let selected = this.state.selected
                    if (name === 'cep_resid') {
						selected.cidadeResid = []
						object.endereco_resid = ''
						object.bairro_resid = ''
						object.fk_cid_resid = null
						populateForm(form, object)
						this.setState({ selected: selected })
					} else {
						selected.cidadeCom = []
						object.endereco_com = ''
						object.bairro_com = ''
						object.fk_cid_com = null

						populateForm(form, object)
						this.setState({ selected: selected })
					}
                }
            })
        }
    }

	handleSaveArquivo(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o arquivo?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) this.submitArquivo(e);
		});
	}

	handleSaveAnuidade(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.editAnuidade ? 'editar' : 'incluir') + ' a anuidade?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) this.submitAnuidade(e);
		});
	}

	validateArquivo(data) {
		let valid = { isValid: true };
		//Consistências

		//Nome
		if (data.get('nomeArquivo')) {
			valid = { isValid: true };
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo NOME.' };
		}

		//Categoria Arquivo
		if (data.get('fk_cta')) {
			valid = { isValid: true };
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo CATEGORIA.' };
		}

		//Retorna resultado
		return valid;
	}

	async showModalArquivo(codigo) {
		// e.stopPropagation()
		// e.preventDefault();
		let edicao = false;
		let pk = '0';

		this.setState({ modalArquivo: { show: true }, editArquivo: edicao, codigo: pk });
	}

	async showModalAnuidade(edicao, anuidade) {
		// e.stopPropagation()
		// e.preventDefault();
		let pk = '0';

		this.setState({ modalAnuidade: { show: true }, editAnuidade: edicao, anuidade });
	}

	handleAddArquivo(e) {
		e.preventDefault();
		this.setState({arquivo: {}, enderecoArquivo: ''});
		document.getElementById('registroAssociadoArquivo').reset();
		this.showModalArquivo();
	}

	handleAddAnuidade(e) {
		e.preventDefault();
		document.getElementById('registroAssociadoAnuidade').reset();
		this.showModalAnuidade(false);
	}

	handleEditAnuidade(e) {
		e.preventDefault();
		let anuidade = this.state.dataAnuidades.find((item) => Number(item.pk_anu) === Number(e.target.name))
		console.log(anuidade)
		anuidade.data_vencimento = dateSql(anuidade.data_vencimento||null);
		if (Number(anuidade.valor_recebido) > 0) {
			swal('Alteração não permitida', 'Anuidade já possui valor recebido.', 'error');
			return
		}
		populateForm(document.getElementById('registroAssociadoAnuidade'), anuidade);
		this.showModalAnuidade(true, anuidade);
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ],
			reverseButtons: true
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	selectCidadeNaturalidade(item) {
		let selected = this.state.selected;
		selected.cidadeNaturalidade = item;
		this.setState({ selected: selected });
	}

	selectInstDoutorado(item) {
		let selected = this.state.selected;
		selected.instDoutorado = item;
		this.setState({ selected: selected });
	}

	selectInstMestrado(item) {
		let selected = this.state.selected;
		selected.instMestrado = item;
		this.setState({ selected: selected });
	}

	selectCidadeCom(item) {
		let selected = this.state.selected;
		selected.cidadeCom = item;
		this.setState({ selected: selected });
	}

	selectCidadeResid(item) {
		let selected = this.state.selected;
		selected.cidadeResid = item;
		this.setState({ selected: selected });
	}

	selectRegiao(item) {
		let selected = this.state.selected;
		selected.regiao = item;
		this.setState({ selected: selected });
	}

	selectUniversidade(item) {
		let selected = this.state.selected;
		selected.uniFormacao = item;
		this.setState({ selected: selected });
	}

	selectCet(item) {
		let selected = this.state.selected;
		selected.cet = item;
		this.setState({ selected: selected });
	}

	selectCategoria(item) {
		let selected = this.state.selected;
		selected.categoria = item;
		this.setState({ selected: selected });
	}

	selectCategoriaArquivo(item) {
		let selected = this.state.selected;
		selected.categoria_arquivo = item;
		this.setState({ selected: selected });
	}

	populaCidade(item, id) {
		if (item.type === 'cidade') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaRegiao(item, id) {
		if (item.type === 'regioes') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaUniversidade(item, id) {
		if (item.type === 'universidades') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaCategoria(item, id) {
		if (item.type === 'categoriasAssociados') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaCategoriaArquivos(item, id) {
		if (item.type === 'categoriasArquivos') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	modalStateArquivo() {
		if (this.state.modalArquivo.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalStateAnuidade() {
		if (this.state.modalAnuidade.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	async handleDownloadBoleto(e, ano) {
		e.preventDefault();
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend+'/api/downloadBoleto?ano='+ano+'&crm='+document.getElementById('numero_crm').value)
		.then((r) => r.blob())
		.then((r) => {
			var url = window.URL.createObjectURL(r);
			var a = document.createElement('a');
			a.href = url;
			a.download = ano+'_'+document.getElementById('numero_crm').value+'.pdf';
			document.body.appendChild(a);
			a.click();    
			a.remove();
		})
	}


	async componentDidMount() {
		//Busca valores para combo de filtro

		//Categorias de Associados
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCategoriasAssociados = this.state.combos;
			await asyncForEach(r, async (item) => {
				let categoriasAssociados = {
					type: 'categoriasAssociados',
					display: item.descricao,
					value: item.pk_cat
				};
				// console.log(cidades)
				await combosCategoriasAssociados.push(categoriasAssociados);
			});
			// console.log(combosCidades)
			this.setState({ combos: combosCategoriasAssociados });
		});

		//Categorias de Arquivos
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasArquivos')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCategoriasArquivos = this.state.combos;
			await asyncForEach(r, async (item) => {
				let categoriasArquivos = {
					type: 'categoriasArquivos',
					display: item.descricao,
					value: item.pk_cta
				};
				// console.log(cidades)
				await combosCategoriasArquivos.push(categoriasArquivos);
			});
			// console.log(combosCidades)
			this.setState({ combos: combosCategoriasArquivos });
		});

		//Regioes
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getRegioes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosRegioes = this.state.combos;
				await asyncForEach(r, async (item) => {
					let regioes = {
						type: 'regioes',
						display: item.descricao,
						value: item.pk_reg
					};
					// console.log(cidades)
					await combosRegioes.push(regioes);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosRegioes });
			});

		//Universidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getUniversidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosUniversidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let universidades = {
						type: 'universidades',
						display: item.descricao,
						value: item.pk_uni
					};
					// console.log(cidades)
					await combosUniversidades.push(universidades);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosUniversidades });
			});

		//Universidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCet')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCets = this.state.combos;
			await asyncForEach(r, async (item) => {
				let cets = {
					type: 'cets',
					display: item.descricao,
					value: item.pk_cet
				};
				// console.log(cidades)
				await combosCets.push(cets);
			});
			// console.log(combosCidades)
			this.setState({ combos: combosCets });
		});

		//Anuidades
		if (getParameterByName('pk')) {
			await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAnuidades?fk_ass='+getParameterByName('pk'))
			.then((r) => r.json())
			.then(async (r) => {
				this.setState({ dataAnuidades: r });
			});
		}

		//Cidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosCidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let cidades = {
						type: 'cidade',
						display: item.descricao,
						value: item.pk_cid
					};
					// console.log(cidades)
					await combosCidades.push(cidades);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosCidades });
			});
		
				//Arquivos
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociadosArquivos?fk_ass='+getParameterByName('pk'))
				.then((r) => r.json())
				.then(async (r) => {
					this.setState({ associadoArquivos: r });
				});

		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));

		if (query.pk > 0) {
			try {
				let test = await fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/static/associados/images/' +
						getParameterByName('pk') +
						'.jpg',
					{
						method: 'GET',
						mode: 'no-cors'
					}
				);
				console.log(test.status);
			} catch (e) {
				console.log('nao tem imagem');
			}

			fetch(
				config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados?pk=' + query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/associados';
					} else {
						let form = document.getElementById('registroAssociados');
						let selectedCidadeCom = this.state.combos.filter((item) => {
							return item.type === 'cidade' && item.value === r[0].fk_cid_com;
						});
						let selectedCidadeResid = this.state.combos.filter((item) => {
							return item.type === 'cidade' && item.value === r[0].fk_cid_resid;
						});
						let selectedCidadeNaturalidade = this.state.combos.filter((item) => {
							return item.type === 'cidade' && item.value === r[0].fk_cidnaturalidade;
						});
						let selectedRegiao = this.state.combos.filter((item) => {
							return item.type === 'regioes' && item.value === r[0].fk_reg;
						});
						let selectedUniversidade = this.state.combos.filter((item) => {
							return item.type === 'universidades' && item.value === r[0].fk_uniformacao;
						});
						let selectedCategoria = this.state.combos.filter((item) => {
							return item.type === 'categoriasAssociados' && item.value === r[0].fk_cat;
						});
						let selectedCet = this.state.combos.filter((item) => {
							return item.type === 'cets' && item.value === r[0].fk_cet;
						});
						let selectedInstDoutorado = this.state.combos.filter((item) => {
							return item.type === 'universidades' && item.value === r[0].fk_inst_doutorado;
						});
						let selectedInstMestrado = this.state.combos.filter((item) => {
							return item.type === 'universidades' && item.value === r[0].fk_inst_mestrado;
						});
						this.setState({
							selected: {
								cidadeCom: selectedCidadeCom[0],
								cidadeResid: selectedCidadeResid[0],
								cidadeNaturalidade: selectedCidadeNaturalidade[0],
								regiao: selectedRegiao[0],
								uniFormacao: selectedUniversidade[0],
								categoria: selectedCategoria[0],
								cet: selectedCet[0],
								instMestrado: selectedInstMestrado[0],
								instDoutorado: selectedInstDoutorado[0]
							},
							temImagem: r[0].tem_imagem,
							enderecoImagem:
								r[0].tem_imagem === 'S'
									? config.protocol +
										'://' +
										config.server +
										':' +
										config.portBackend +
										'/static/associados/images/' +
										query.pk +
										'.jpg'
									: ''
						});
						r[0].data_aspirante = dateSql(r[0].data_aspirante);
						r[0].data_aspirante_adjunto = dateSql(r[0].data_aspirante_adjunto);
						r[0].data_remido = dateSql(r[0].data_remido);
						r[0].data_ativo = dateSql(r[0].data_ativo);
						r[0].data_filiacao = dateSql(r[0].data_filiacao);
						r[0].data_nasc = dateSql(r[0].data_nasc);
						r[0].data_adjunto = dateSql(r[0].data_adjunto);
						r[0].cpf = r[0].cpf ? r[0].cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4") : ''
						populateForm(form, r[0]);
					}
				});
			this.setState({ edit: true });
		}
	}

	async submitData(e) {
		e.preventDefault();

		//Pega valores do form
		const form = document.getElementById('registroAssociados');
		const data = new FormData(form);

		data.delete('imagem');

		let simnao = [ 'acesso_area_restrita', 'certif_cea', 'certif_tsa', 'certif_sba', 'mestrado', 'doutorado' ];

		for (let field of simnao) {
			let value = data.get(field);
			if (value !== 'S') {
				data.set(field, 'N');
			}
		}

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			// console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		data.append('tem_imagem', this.state.temImagem || 'N');

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			if (String(value) === '') {
				object[key] = null;
			} else {
				object[key] = value;
			}
		});
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editAssociados?pk=' +
					getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (this.state.novaImagem) {
						await axios.get(
							config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/api/saveImageAssociado?pk=' +
								getParameterByName('pk')
						);
					}
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoAssociados', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then(async (r) => {
					if (this.state.novaImagem) {
						await axios.get(
							config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/api/saveImageAssociado?pk=' +
								r.pk
						);
					}
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	downloadArquivo(e, nome, formato, fk_ass) {
		e.preventDefault();
		
		//open download link in new page
		window.open( 
			config.protocol +
			'://' +
			config.server +
			':' +
			config.portBackend +
			'/static/associados/arquivos'+
			'/' + fk_ass +
			'/' + nome + '.' + formato
		);

		//redirect current page to success page
		window.focus();

	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteAssociadosArquivos?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								// var event = new Event('build');
								this.componentDidMount();
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	closeModal() {
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao cancelar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) this.setState({ modalArquivo: { show: false }, modalAnuidade: { show: false } });
		});
	}

	handleDeleteAnuidade(e, pk, valor_recebido) {
		e.preventDefault();
		if (valor_recebido > 0) {
			swal('Exclusão não pode ser realizada', 'Não foi possível excluir a anuidade por já possuir valor recebido!', 'error');
		} else {
			swal({
				dangerMode: true,
				title: 'Atenção!',
				text: 'O registro selecionado será excluído. Confirma?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) {
					//Delete
					fetch(
						config.protocol +
							'://' +
							config.server +
							':' +
							config.portBackend +
							'/api/deleteAnuidades?pk=' +
							pk,
						{
							method: 'POST',
							headers: {
								'Content-type': 'application/json'
							},
							body: JSON.stringify({})
						}
					)
						.then((r) => r.json())
						.then((r) => {
							console.log(r);
							if (r.message === 'Success!') {
								swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
									this.componentDidMount();
								});
							} else {
								swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
							}
						});
				}
			});
		}
	}

	async submitAnuidade(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroAssociadoAnuidade');
		const data = new FormData(form);
		const fk = Number(getParameterByName('pk'));

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		data.append('fk_ass', fk);
		data.append('fk_fpg', 0)

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			if (value !== '') {
				object[key] = value;
			} else {
				object[key] = null;
			}
		});
		console.log(object)

		if (object.valor) {
			object.valor = Number(object.valor.replace(',', '.'))
		} else {
			swal('Inclusão não realizada', 'Informe o valor!', 'error');
			return
		}
		console.log(this.state.editAnuidade)
		console.log(object)

		if (!object.data_vencimento) {
			swal('Inclusão não realizada', 'Informe a data!', 'error');
			return
		}

		if (((this.state.dataAnuidades.find(item => (item.ano === Number(object.ano) && (this.state.editAnuidade ? (item.pk_anu !== Number(this.state.anuidade.pk_anu)) : true)))) || (Number(object.ano) === 0))) {
			swal('Inclusão não realizada', 'Anuidade já incluída ou inválida.', 'error');
			return
		}
		var json = JSON.stringify(object);


		//Post no server
		
		if (this.state.editAnuidade) {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editAnuidades?pk='+this.state.anuidade.pk_anu,
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			).then(r=>{
				swal('Edição realizada', 'Registro editado com sucesso!', 'success').then((result) => {
					this.setState({ modalAnuidade: { show: false } });
					this.componentDidMount();
				});
			}).catch(error=>{
				swal('Edição não realizada', 'Registro não foi editado. Verifique os campos.', 'error');
			})
		} else {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/novoAnuidade',
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			).then(r=>{
				swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
					this.setState({ modalAnuidade: { show: false } });
					this.componentDidMount();
				});
			}).catch(error=>{
				swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
			})
		}
		

	}

	async submitArquivo(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroAssociadoArquivo');
		const data = new FormData(form);
		const fk = Number(getParameterByName('pk'));

		let validate = this.validateArquivo(data);

		
		data.delete('arquivo');

		if (!validate.isValid) {
			swal(validate.title, validate.message, 'error');
			throw validate;
		}

		console.log(data.keys());

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			// Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
			console.log(name, input.value);
		}


		//Insere FK
		data.append('fk_ass', fk);
		if (this.state.novoArquivo) {
			data.append('arquivo', this.state.arquivo.name);
			data.append('formato', this.state.arquivo.name.split('.').pop())
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			if (value !== '') {
				object[key] = value;
			} else {
				object[key] = null;
			}
		});
		var json = JSON.stringify(object);

		//Post no server

		fetch(
			config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/saveArquivoAssociado?fk=' +
				this.state.codigo,
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			}
		).then(r=>{
			swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
				this.setState({ modalArquivo: { show: false } });
				this.componentDidMount();
			});
		}).catch(error=>{
			swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
		})

	}
	

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	selectImage(e) {
		e.preventDefault();
		$('#html_btnImg').click();
	}

	handleCpf (e){
		e.preventDefault()
		let valor = e.target.value
		e.target.value = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4")
	}

	async handleSelectedFile(e) {
		$('#arquivo').val(true);
		let file = e.target.files[0];
		let data = new FormData();
		data.append('arquivo', file, file.name);
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivoAssociado',
			data
		);
		this.setState({
			arquivo: file,
			novoArquivo: true,
			temArquivo: 'S',
			enderecoArquivo:
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/static/associados/arquivos/temp.'+file.name.split('.').pop()
		});
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2020; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	async handleSelectedImage(e) {
		$('#imagem').val(true);
		let file = e.target.files[0];
		let data = new FormData();
		data.append('image', file, file.name);
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadImageAssociado',
			data
		);
		this.setState({
			image: file,
			novaImagem: true,
			temImagem: 'S',
			enderecoImagem:
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/static/associados/images/temp.jpg'
		});
	}

	populaAnos(item, id) {
		return (
			<option value={item} key={id}>
				{item}
			</option>
		);
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		console.log(this.state.temImagem, this.state.enderecoImagem);
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidade';
		});
		let regioes = this.state.combos.filter((item) => {
			return item.type === 'regioes';
		});
		let universidades = this.state.combos.filter((item) => {
			return item.type === 'universidades';
		});
		let cets = this.state.combos.filter((item) => {
			return item.type === 'cets';
		});
		let categorias = this.state.combos.filter((item) => {
			return item.type === 'categoriasAssociados';
		});
		let categorias_arquivos = this.state.combos.filter((item) => {
			return item.type === 'categoriasArquivos';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Associados" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Associado</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
					<div className={this.modalStateArquivo()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Registro de Arquivo</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="registroAssociadoArquivo" name="registroAssociadoArquivo" onSubmit={this.submitArquivo}>
												<div>
													<label className="labelModal">Nome do Arquivo</label>
													<input
														type="text"
														id="nomeArquivo"
														name="nomeArquivo"
														className="form-control"
														data-parse="uppercase"
													/>
												</div>
												<div>
													<label className="labelModal">Categoria de Arquivo</label>
													<Autocomplete
														field="fk_cta"
														items={categorias_arquivos}
														inModal="-inModal"
														selected={this.state.selected.categoria_arquivo || []}
														select={this.selectCategoriaArquivo}
													/>
												</div>
												<div style={{ textAlign: 'left', marginTop: '15px' }}>
													<input
														type="file"
														name="arquivo"
														id="html_btn"
														accept=".pdf, .jp*, .png, .xl*, .doc*"
														onChange={this.handleSelectedFile}
													/>
													{this.state.arquivo.name || 'Nenhum arquivo selecionado'}
													<button
														className="ModalButton"
														type="file"
														onClick={this.selectFile}
														style={{ width: '110px' }}
													>
														Selecionar Arquivo
													</button>
												</div>
												<iframe
													src={this.state.enderecoArquivo}
													style={{
														border: 'none',
														width: '100%',
														height: this.state.enderecoArquivo ? '400px' : '10px',
														marginTop: '15px'
													}}
													title="Previsão do Arquivo"
												/>
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSaveArquivo}>
												Salvar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div className={this.modalStateAnuidade()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Registro de Anuidade</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="registroAssociadoAnuidade" name="registroAssociadoAnuidade" onSubmit={this.submitAnuidade}>
												<div>
													<label htmlFor="ano" className="labelModal">
														Ano:
													</label>
													<select
														name="ano"
														id="ano"
														className="form-control"
														style={{ width: '100px' }}
													>
														<option value="">Selecione</option>
														{anos}
													</select>
												</div>
												<div>
													<label className="labelModal">Data de Vencimento</label>
													<input
														type="date"
														id="data_vencimento"
														name="data_vencimento"
														className="form-control"
													/>
												</div>
												<div>
													<label className="labelModal">Valor (R$):</label>
													<input
														type="text"
														id="valor"
														name="valor"
														className="form-control"
														onBlur={(e) => {
															onBlurCurrency(e);
															// this.calculaPendente(e);
														}}
													/>
												</div>
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSaveAnuidade}>
												Salvar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroAssociados" name="registroAssociados" onSubmit={this.submitData}>
								<div style={{ height: 'auto' }}>
									<div className="row">
										<div className="fields"  style={{ marginLeft: '15px' }}>
											<div className="row">
												<div className="col-sm-5" style={{ marginBottom: '-10px' }}>
												<label>Categoria:</label>
												<Autocomplete
													field="fk_cat"
													items={categorias}
													inModal=""
													selected={this.state.selected.categoria || []}
													select={this.selectCategoria}
												/>
												</div>
											</div>	
											<div className="row">
												<div className="col-sm-5">
													<label>Aspirante:</label>
													<input type="date" name="data_aspirante" className="form-control" />
												</div>
											{/* </div> */}
											{/* <div className="row"> */}
												<div className="col-sm-5">
													<label>Ativo:</label>
													<input type="date" name="data_ativo" className="form-control" />
												</div>
											</div>
											<div className="row">
												<div className="col-sm-5">
													<label>Remido:</label>
													<input type="date" name="data_remido" className="form-control" />
												</div>
											{/* </div> */}
											{/* <div className="row"> */}
												<div className="col-sm-5">
													<label>Aspirante Adjunto:</label>
													<input
														type="date"
														name="data_aspirante_adjunto"
														className="form-control"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-5">
													<label>Adjunto:</label>
													<input type="date" name="data_adjunto" className="form-control" />
												</div>
											{/* </div> */}
											{/* <div className="row"> */}
												<div className="col-sm-5">
													<label>Filiado:</label>
													<input type="date" name="data_filiacao" className="form-control" />
												</div>
											</div>
										</div>
										<div className="foto">
											<div style={{ width: '150px', height: '180px' }}>
												<div className="div_imagem">
													<img
														src={
															this.state.temImagem === 'S' ? (
																this.state.enderecoImagem
															) : (
																config.protocol +
																'://' +
																config.server +
																':' +
																config.portBackend +
																'/static/associados/images/no-image.png'
															)
														}
														alt="Associado"
														className="imagem"
													/>
												</div>
												<div className="div_botao">
													<input
														type="file"
														name="imagem"
														id="html_btnImg"
														accept=".jpg, .png, .jpeg, .bmp"
														onChange={this.handleSelectedImage}
													/>
													<button className="botao" type="file" onClick={this.selectImage}>
														Selecionar Foto
													</button>
												</div>
											</div>
										</div>
									</div>
									<legend style={{ marginLeft: '5%', width: '90%' }} />
									<h2>Dados Pessoais</h2>
									<div className="row">
										<div className="col-sm-4">
											<label>Nome:</label>
											<input
												type="text"
												id="nome"
												name="nome"
												data-parse="uppercase"
												className="form-control"
											/>
										</div>
										<div className="col-sm-2" style={{ width: '120px' }}>
											<label>Sexo:</label>
											<select
												name="sexo"
												id="sexo"
												data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Selecione</option>
												<option value="M">Masculino</option>
												<option value="F">Feminino</option>
											</select>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>RG:</label>
											<input
												type="text"
												id="rg"
												name="rg"
												className="form-control"
												// data-parse="number"
											/>
										</div>
										<div className="col-sm-2" style={{ width: '150px'  }}>
											<label>CPF:</label>
											<input
												type="text"
												id="cpf"
												name="cpf"
												className="form-control"
												maxLength="14"
												onChange={this.handleCpf}
												// data-parse="number"
											/>
										</div>
										<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
											<label>Região:</label>
											<Autocomplete
												field="fk_reg"
												items={regioes}
												inModal=""
												selected={this.state.selected.regiao || []}
												select={this.selectRegiao}
											/>
										</div>
										<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
											<label>Naturalidade:</label>
											<Autocomplete
												field="fk_cidnaturalidade"
												items={cidades}
												inModal=""
												selected={this.state.selected.cidadeNaturalidade || []}
												select={this.selectCidadeNaturalidade}
											/>
										</div>
										<div className="col-sm-4" style={{ width: '200px' }}>
											<label>Nascimento:</label>
											<input
												type="date"
												id="data_nasc"
												name="data_nasc"
												className="form-control"
											/>
										</div>
										<div className="col-sm-4" style={{ width: '180px' }}>
											<label>Senha Área Retrita:</label>
											<input
												type="text"
												id="senha_area_restrita"
												name="senha_area_restrita"
												// data-parse="uppercase"
												className="form-control"
											/>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>Acesso Área Restrita:</label>
											<select
												name="acesso_area_restrita"
												id="acesso_area_restrita"
												// data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Não</option>
												<option value="S">Sim</option>
											</select>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>Situação SBA:</label>
											<input
												type="text"
												id="quitacao_sba"
												name="quitacao_sba"
												className="form-control"
												// data-parse="number"
											/>
										</div>
									</div>
									<h2>Profissional</h2>
									<div className="row">
										<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
											<label>Formação:</label>
											<Autocomplete
												field="fk_uniformacao"
												items={universidades}
												inModal=""
												selected={this.state.selected.uniFormacao || []}
												select={this.selectUniversidade}
											/>
										</div>
										<div className="col-sm-3" style={{ width: '150px' }}>
											<label>Matrícula SBA:</label>
											<input
												type="text"
												id="matricula_sba"
												name="matricula_sba"
												className="form-control"
												// data-parse="number"
											/>
										</div>
										<div className="col-sm-2">
											<label style={{ display: 'block' }}>CRM:</label>
											<input
												type="text"
												id="numero_crm"
												name="numero_crm"
												style={{ display: 'inline', width: '130px', marginRight: '5px' }}
												className="form-control"
												// data-parse="number"
											/>
											{/* <LinkContainer to={'/apergs/associados/registro'}> */}
												<Icon
													size={20}
													icon={iosSearchStrong}
													name='busca_crm'
													className="iconButton"
													onClick={ (e) => { window.open('https://www.cremers.org.br/index.php?indice=18&busc=1&criterio_Pesquisa='+String($('#numero_crm').val()), '_blank'); }}
												/>
											{/* </LinkContainer> */}
											<div style={{ marginTop: '5px', marginLeft: '3px', float: 'right'}}>
												<label>Valores pré migração:</label>
											</div>
										</div>
										<div className="col-sm-3" style={{ width: '150px' }}>
											<label>Ano Formatura:</label>
											<input
												type="text"
												id="ano_formatura"
												name="ano_formatura"
												className="form-control"
												// data-parse="number"
											/>
											<input
												type="text"
												id="old_ano_formatura"
												name="old_ano_formatura"
												className="form-control"
												readOnly
											/>
										</div>
										<div className="col-sm-3" style={{ width: '150px' }}>
											<label>Inicio Especialização:</label>
											<input
												type="text"
												id="ano_inic_espec"
												name="ano_inic_espec"
												className="form-control"
												// data-parse="number"
											/>
											<input
												type="text"
												id="old_inicio_especializacao"
												name="old_inicio_especializacao"
												className="form-control"
												readOnly
											/>
										</div>
										<div className="col-sm-3" style={{ width: '140px' }}>
											<label>Fim Especialização:</label>
											<input
												type="text"
												id="ano_fim_espec"
												name="ano_fim_espec"
												className="form-control"
												maxLength="10"
												// data-parse="number"
											/>
											<input
												type="text"
												id="old_final_especializacao"
												name="old_final_especializacao"
												className="form-control"
												readOnly
											/>
										</div>
										<div className="col-sm-3" style={{ marginBottom: '-10px', width: '300px' }}>
											<label>CET:</label>
											<Autocomplete
												field="fk_cet"
												items={cets}
												inModal=""
												selected={this.state.selected.cet || []}
												select={this.selectCet}
											/>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>Coopa:</label>
											<select
												name="coopa"
												id="coopa"
												// data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Não</option>
												<option value="S">Sim</option>
											</select>
										</div>
									</div>
									<legend style={{ marginLeft: '5%', width: '90%', marginTop: '30px' }} />
									<h2>Contato</h2>
									<div className="row">
										<div className="col-sm-4">
											<label>Email:</label>
											<input type="text" name="email" className="form-control notUpper" />
										</div>
										<div className="col-sm-2">
											<label>Telefone Residencial:</label>
											<input
												type="text"
												// data-parse="number"
												name="telefone_resid"
												className="form-control"
											/>
										</div>
										<div className="col-sm-2">
											<label>Telefone Celular:</label>
											<input
												type="text"
												// data-parse="number"
												name="telefone_celular"
												className="form-control"
											/>
										</div>
										<div className="col-sm-2">
											<label>Telefone Comercial:</label>
											<input
												type="text"
												// data-parse="number"
												name="telefone_comercial"
												className="form-control"
											/>
										</div>
										<div className="col-sm-2">
											<label>Telefone Hospitalar:</label>
											<input
												type="text"
												// data-parse="number"
												name="telefone_hospitalar"
												className="form-control"
											/>
										</div>
									</div>
									<h2>Certificados</h2>
									<div className="row">
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>TEA:</label>
											<select
												name="certif_cea"
												id="certif_cea"
												// data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Não</option>
												<option value="S">Sim</option>
											</select>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>TSA:</label>
											<select
												name="certif_tsa"
												id="certif_tsa"
												// data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Não</option>
												<option value="S">Sim</option>
											</select>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>SBA:</label>
											<select
												name="certif_sba"
												id="certif_sba"
												// data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Não</option>
												<option value="S">Sim</option>
											</select>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>Mestrado:</label>
											<select
												name="mestrado"
												id="mestrado"
												// data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Não</option>
												<option value="S">Sim</option>
											</select>
										</div>
										<div className="col-sm-3" style={{ width: '180px' }}>
											<label>Área Mestrado:</label>
											<input
												type="text"
												id="area_mestrado"
												name="area_mestrado"
												className="form-control"
												data-parse="uppercase"
											/>
										</div>
										<div className="col-sm-3" style={{ marginBottom: '-10px', width: '300px' }}>
											<label>Instituição Mestrado:</label>
											<Autocomplete
												field="fk_inst_mestrado"
												items={universidades}
												inModal=""
												selected={this.state.selected.instMestrado || []}
												select={this.selectInstMestrado}
											/>
										</div>
										<div className="col-sm-2" style={{ width: '150px' }}>
											<label>Doutorado:</label>
											<select
												name="doutorado"
												id="doutorado"
												// data-parse="uppercase"
												className="form-control"
											>
												<option value="N">Não</option>
												<option value="S">Sim</option>
											</select>
										</div>
										<div className="col-sm-3" style={{ width: '180px' }}>
											<label>Área Doutorado:</label>
											<input
												type="text"
												id="area_doutorado"
												name="area_doutorado"
												className="form-control"
												data-parse="uppercase"
											/>
										</div>
										<div className="col-sm-3" style={{ marginBottom: '-10px', width: '300px' }}>
											<label>Instituição Doutorado:</label>
											<Autocomplete
												field="fk_inst_doutorado"
												items={universidades}
												inModal=""
												selected={this.state.selected.instDoutorado || []}
												select={this.selectInstDoutorado}
											/>
										</div>
									</div>
									<legend style={{ marginLeft: '5%', width: '90%', marginTop: '30px' }} />
									<h2>Endereço para Correspondência</h2>
									<div className="row">
										<div className="col-sm-2">
											<label style={{ display: 'block' }}>CEP:</label>
											<input
												type="text"
												name="cep_resid"
												style={{ display: 'inline', width: '90px', marginRight: '5px' }}
												// data-parse="number"
												className="form-control"
											/>
											{/* <button id='buscaCEP' className='ButtonIcon' onClick={this.enviaCEP}> */}
											<Icon
												size={20}
												icon={iosSearchStrong}
												name='busca_cep_resid'
												className="iconButton"
												onClick={(e) => {this.handleCep(e, 'cep_resid')}}
											/>
											{/* </button> */}
										</div>
										<div className="col-sm-12">
											<label>Endereço:</label>
											<input
												type="text"
												name="endereco_resid"
												data-parse="uppercase"
												className="form-control"
											/>
										</div>
										<div className="col-sm-3">
											<label>Bairro:</label>
											<input
												type="text"
												name="bairro_resid"
												data-parse="uppercase"
												className="form-control"
											/>
										</div>
										<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
											<label>Cidade:</label>
											<Autocomplete
												field="fk_cid_resid"
												items={cidades}
												inModal=""
												selected={this.state.selected.cidadeResid || []}
												select={this.selectCidadeResid}
											/>
										</div>
										<div className="col-sm-12">
											<label>Observações:</label>
											<textarea
												type="text"
												maxLength="2000"
												name="observacoes"
												data-parse="uppercase"
												className="form-control"
												style={{ height: '100px' }}
											/>
										</div>
									</div>
									<h2>Endereço Comercial</h2>
									<div className="row">
										<div className="col-sm-2">
											<label style={{ display: 'block' }}>CEP:</label>
											<input
												type="text"
												name="cep_com"
												style={{ display: 'inline', width: '90px', marginRight: '5px' }}
												// data-parse="number"
												className="form-control"
											/>
											<Icon
												size={20}
												icon={iosSearchStrong}
												name='busca_cep_com'
												className="iconButton"
												onClick={(e) => {this.handleCep(e, 'cep_com')}}
											/>
										</div>
										<div className="col-sm-12">
											<label>Endereço:</label>
											<input
												type="text"
												name="endereco_com"
												data-parse="uppercase"
												className="form-control"
											/>
										</div>
										<div className="col-sm-3">
											<label>Bairro:</label>
											<input
												type="text"
												name="bairro_com"
												data-parse="uppercase"
												className="form-control"
											/>
										</div>
										<div className="col-sm-3" style={{ marginBottom: '-10px' }}>
											<label>Cidade:</label>
											<Autocomplete
												field="fk_cid_com"
												items={cidades}
												inModal=""
												selected={this.state.selected.cidadeCom || []}
												select={this.selectCidadeCom}
											/>
										</div>
									</div>
									<legend style={{ marginLeft: '5%', width: '90%', marginTop: '30px' }} />
									<h2>Arquivos</h2>
									{ this.state.edit ? (
									<div>
										<button
											className="buttonVoltar"
											style={{
												marginRight: '10px',
												marginTop: '-40px',
												width: '150px',
												float: 'right'
											}}
											onClick={this.handleAddArquivo}
										>
											<Icon size={20} icon={ic_add_circle} />
											Incluir Arquivo
										</button>
										<div style={{ paddingBottom: '30px' }}>
											<div style={{ marginTop: '30px' }}>
												<div className="divTabela">
													<ReactTable
														data={this.state.associadoArquivos}
														previousText="Anterior"
														nextText="Próximo"
														loadingText="Carregando..."
														pageText="Página"
														ofText="de"
														rowsText="registros"
														noDataText="Nenhum registro encontrado"
														columns={[
															{
																Header: 'Codigo',
																accessor: 'pk_arq',
																width: 200,
																show: false
															},
															{
																Header: 'Arquivo',
																accessor: 'nome',
																width: 200
															},
															{
																Header: 'Formato',
																accessor: 'formato',
																width: 80
															},
															{
																Header: 'Categoria do Arquivo',
																accessor: 'nomeCta',
																width: 130
															},
															{
																Header: 'Opções',
																minWidth: 300,
																Cell: (row) => {
																	return (
																		<div className="buttonsDetailColumn">
																			<button
																				className="buttonDetailColumn"
																				onClick={(e) => {
																					this.downloadArquivo(e, row.row.nome, row.row.formato, getParameterByName('pk'));
																				}}
																				
																			>
																				<Icon size={20} icon={download} style={{ marginRight: '5px' }}/>
																				Download
																			</button>
																			<button
																				className="buttonDetailColumn"
																				onClick={(e) => {
																					this.handleDelete(e, row.row.pk_arq);
																				}}
																			>
																				<Icon size={20} icon={iosTrash} />
																				Excluir
																			</button>
																		</div>
																	);
																}
															}
														]}
														defaultSorted={[
															{
																id: 'nome',
																desc: false
															}
														]}
														defaultPageSize={5}
														className="-striped -highlight"
													/>
												</div>
											</div>
										</div>
									</div>
									) : (
										<div>
											Finalize o cadastro para incluir arquivos.
										</div>
									)}
									<br />
									<h2>Anuidades</h2>
									{ this.state.edit ? (
									<div>
										<button
											className="buttonVoltar"
											style={{
												marginRight: '10px',
												marginTop: '-40px',
												width: '150px',
												float: 'right'
											}}
											onClick={this.handleAddAnuidade}
										>
											<Icon size={20} icon={ic_add_circle} />
											Incluir Anuidade
										</button>
										<div style={{ paddingBottom: '30px' }}>
											<div style={{ marginTop: '30px' }}>
												<div className="divTabela">
													<ReactTable
														data={this.state.dataAnuidades}
														previousText="Anterior"
														nextText="Próximo"
														loadingText="Carregando..."
														pageText="Página"
														ofText="de"
														rowsText="registros"
														noDataText="Nenhum registro encontrado"
														columns={[
															{
																Header: 'Codigo',
																accessor: 'pk_anu',
																width: 200,
																show: false
															},
															{
																Header: 'Ano',
																accessor: 'ano',
																width: 70
															},
															{
																Header: 'Data Vencimento',
																accessor: 'data_vencimento',
																Cell: (row) => {
																	return (
																		<div>
																			{garanteDate(row.row.data_vencimento)}
																		</div>
																	);
																}, 
																width: 100
															},
															{
																Header: 'Valor',
																accessor: 'valor',
																width: 70
															},
															{
																Header: 'Valor Recebido',
																accessor: 'valor_recebido',
																width: 100
															},
															{
																Header: 'Data Pagamento',
																accessor: 'data_baixa',
																Cell: (row) => {
																	return (
																		<div>
																			{garanteDate(row.row.data_baixa)}
																		</div>
																	);
																}, 
																width: 100
															},
															{
																Header: 'Forma de Pagamento',
																accessor: 'strPagto',
																width: 150
															},
															{
																Header: 'Observação',
																accessor: 'obs_baixa',
															},
															{
																Header: 'Opções',
																width: 450,
																Cell: (row) => {
																	return (
																		<div className="buttonsDetailColumn">
																			<button
																				className="buttonDetailColumn"
																				onClick={this.handleEditAnuidade}
																				name={row.row.pk_anu}
																			>
																				<Icon
																					size={20}
																					icon={edit}
																					style={{ marginRight: '5px', marginLeft: '-5px' }}
																				/>
																				Editar

																			</button>
																			<button
																				className="buttonDetailColumn"
																				onClick={(e) => {
																					this.handleDownloadBoleto(e, row.row.ano);
																				}}
																				name={row.row.pk_anu}
																			>
																				<Icon
																					style={{ marginRight: `3px` }}
																					size={20}
																					icon={download}																				
																				/>
																				Download

																			</button>
																			<button
																				className="buttonDetailColumn"
																				onClick={e => this.handleDeleteAnuidade(e, row.row.pk_anu, row.row.valor_recebido)}
																				name={row.row.pk_anu}
																			>
																				<Icon
																					size={20}
																					icon={iosTrash}
																					style={{ marginRight: '5px', marginLeft: '-5px' }}
																				/>
																				Excluir

																			</button>
																			<LinkContainer to={'/sargs/anuidades?pk='+row.row.pk_anu+'&situacao=T&filtered=true'} >
																				<button
																					className="buttonDetailColumn"
																				>
																					<Icon 
																						style={{ marginRight: `3px` }}
																						size={20}
																						icon={ic_more}																				
																					/>
																					Mais
																				</button>
																			</LinkContainer>
																			{/* </button> */}

																		</div>
																	);
																}	
															},
														]}
														defaultSorted={[
															{
																id: 'ano',
																desc: true
															}
														]}
														defaultPageSize={5}
														className="-striped -highlight"
													/>
												</div>
											</div>
										</div>
									</div>
									) : (
										<div>
											Finalize o cadastro para incluir arquivos.
										</div>
									)}
									<br />
									<div className="buttonsCadastro">
										<button className="buttonSalvar" onClick={this.handleSave}>
											Salvar
										</button>
										<button className="buttonVoltar" onClick={this.handleBack}>
											Voltar
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroAssociados;
